import React from 'react';

import LinkedInConnect from 'components/LinkedInConnect';

class LogInModalContents extends React.Component {
  render() {
    return (
      <div className='signup auth-modal'>
        <h3 className='welcome text-center'>
          Welcome Back!
        </h3>
        <p className="help-text text-center">
          Please sign back in with LinkedIn to continue browsing opportunities.
        </p>
        <LinkedInConnect />
      </div>
    );
  }
}

export default LogInModalContents;
