import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { Col, Well } from 'react-bootstrap';

import { submitPassword } from 'actions/actions';
import { REFERRAL_MEDIUMS } from 'constants/constants';

import LoggedOutPage from 'components/LoggedOutPage';
import EntryPasswordForm from 'components/EntryPasswordForm';
import SignUpModalContents from 'components/SignUpModalContents';
import LogInModalContents from 'components/LogInModalContents';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { createMessage } from '../actions/actions';

import CareersLogo from 'images/first_round_careers_logo.png';
import 'stylesheets/application/signup.less';
import MagicLinkModalContents from './MagicLinkModalContents';
import { sendMagicLink } from '../actions/actions';

class SignUpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validLinkedinUrl: null,
      validPassword: null,
      signUpClicked: false,
      logInClicked: false,
      magicLinkClicked: false,
    };
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.onSubmitMagicLink = this.onSubmitMagicLink.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(isOpen) {
    this.setState({ isModalOpen: isOpen });
  }

  getReferralMedium() {
    const code = this.props.match.params.code;
    const referralFromCode = REFERRAL_MEDIUMS[code];

    if (referralFromCode) {
      return referralFromCode;
    }

    return REFERRAL_MEDIUMS[`personal`];
  }

  handlePasswordSubmit(values) {
    return this.props.submitPassword({entry: values})
      .then(() => {
        this.setState({
          validPassword: values.password,
          validLinkedinUrl: values.linkedin_url,
          signUpClicked: true,
        });
      })
      .catch(error => {
        const errorObject = {password: error.body.error};
        this.props.createMessage({
          body: error.body.error,
          dismissable: true,
          type: `error`,
          expires: false,
        });
        throw new SubmissionError(errorObject);
      });
  }

  getModalType() {
    if (this.state.logInClicked) {
      return `LogInModal`;
    } else if (this.state.signUpClicked) {
      return `SignUpModal`;
    } else if (this.state.magicLinkClicked) {
      return `MagicLinkModal`;
    }
  }

  onSubmitMagicLink(values) {
    this.props.sendMagicLink(values.email)
      .then(() => {
        this.props.createMessage({
          body: `Login link sent to ${values.email}`,
          dismissable: true,
          type: `success`,
          expires: true,
        });
        this.setState({magicLinkClicked: false});
        // Close the modal here
        this.props.closeModal();
      })
      .catch(error => {
        this.props.createMessage({
          body: error.body.error,
          dismissable: true,
          type: `error`,
          expires: false,
        });
      });
  }
  

  render() {
    const referralMedium = this.getReferralMedium();
    const modalType = this.getModalType();

    return (
      <Col md={8} mdOffset={2} className="signup text-center">
        <Modal
          open={modalType === `SignUpModal`}
          onClose={() => this.setState({signUpClicked: false})}
        >
          <SignUpModalContents
            referralMedium={referralMedium}
            password={this.state.validPassword || `Cold Inbound`}
            linkedin_url={this.state.validLinkedinUrl}
          />
        </Modal>
        <Modal
          open={modalType === `LogInModal`}
          onClose={() => this.setState({logInClicked: false})}
        >
          <LogInModalContents />
        </Modal>
        <Modal
          open={modalType === `MagicLinkModal`}
          onClose={() => this.setState({magicLinkClicked: false})}
        >
          <MagicLinkModalContents closeModal={() => this.toggleModal(false)} onSubmit={this.onSubmitMagicLink} />
        </Modal>
        <img
          src={CareersLogo}
          className="logo center-block"
        />
        <div className="body">
          <h1>Matching you with world&#8209;changing technology companies</h1>
          {referralMedium === REFERRAL_MEDIUMS[`personal`] &&
            <EntryPasswordForm
              onSubmit={this.handlePasswordSubmit}
            />
          }
          {referralMedium !== REFERRAL_MEDIUMS[`personal`] &&
            <div className='public-signup'>
              <Well bsSize="large" className='explain-text'>
                <div className='title'>1. Connect to LinkedIn</div>
                <div>We&apos;ll grab your basic information.</div>
                <div className='title'>2. Tell us about your interests</div>
                <div>We&apos;ll automatically match you to the roles that fit your preferences.</div>
                <div className='title'>3. Discover amazing opportunities</div>
                <div>Explore more than 2,000 open roles across over 150 companies.</div>
              </Well>
              <div className='signup-button-container'>
                <Button
                  displayType='primary'
                  onClick={() => this.setState({signUpClicked: true})}
                >
                  Sign Up
                </Button>
              </div>
            </div>
          }
          <hr />
          <div className='login'>
            Already have an account? &nbsp;
            <div className='login-buttons'>
              <Button
                displayType='default'
                tagType='button'
                onClick={() => this.setState({logInClicked: true})}
                id='button-login'
              >
                Log In with LinkedIn
              </Button>
            </div>
            <div className='login'>
              Hiring Manager or Admin? &nbsp;
              <div className='login-buttons'>
                <Button
                  displayType='default'
                  tagType='button'
                  onClick={() => this.setState({magicLinkClicked: true})}
                  id='button-magic-link'
                >
                  Email me a login link
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}

SignUpPage.propTypes = {
  submitPassword: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  createMessage: PropTypes.func.isRequired,
  sendMagicLink: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    submitPassword: (request) => dispatch(submitPassword(request)),
    createMessage: (payload) => dispatch(createMessage(payload)),
    sendMagicLink: (email) => dispatch(sendMagicLink(email)),
  };
}

const SignUpPageWithState = connect(
  null,
  mapDispatchToProps
)(SignUpPage);

export default LoggedOutPage(SignUpPageWithState);
