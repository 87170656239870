import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';

import Button from 'components/Button';
import 'stylesheets/application/entry-password-form.less';


class EntryPasswordForm extends React.Component {
  render() {
    return (
      <form autoComplete='off' className='entry-password-form' onSubmit={this.props.handleSubmit}>
        <Field
          name='password'
          component='input'
          placeholder='Referral Code*'
          type='password'
          required={true}
          className='entry-input password-input'
        />
        <Field
          name='linkedin_url'
          component='input'
          placeholder='LinkedIn Profile URL*'
          type='text'
          required={true}
          className='entry-input linkedin-url-input'
        />
        <Button
          type='submit'
          displayType='primary'
          id='password-submit'
        >
          Sign Up
        </Button>
      </form>
    );
  }
}

EntryPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired, // redux-form passes this in
};

const EntryPasswordReduxForm = reduxForm({
  form: `password`, // a unique identifier for this form
})(EntryPasswordForm);

export default EntryPasswordReduxForm;
