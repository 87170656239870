import React from 'react';
import PropTypes from 'prop-types';
import { uploadResume } from 'actions/actions';
import { DirectUpload } from "@rails/activestorage";

import 'stylesheets/application/file-upload-field.less';

class FileUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
    };
  }
  handleChange(e) {
    const url = `/rails/active_storage/direct_uploads`;
    const {
      input: { onChange }
    } = this.props
    this.setState({
      file: e.target.files[0],
    });
    const file = event.target.files[0];

    const upload = new DirectUpload(file, url, this);
    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        onChange(blob.signed_id);
      }
    });
    onChange(file);
  }
  render() {
    return (
      <div className='file-upload-field'>
        <input
          type="file"
          onChange= {this.handleChange.bind(this)}
          accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
        />
      </div>
    );
  }
}

export default FileUploadField;
