import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import 'stylesheets/application/linkedin-connect.less';
import LinkedinLogo from 'images/linkedin-logo.png';

class LinkedInConnect extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const query = queryString.stringify({
      onboarding_status: this.props.onboardingStatus,
      entry_password_used: this.props.password,
      linkedin_url: this.props.linkedin_url,
      referral_medium: this.props.referralMedium,
      sign_up: !!this.props.isSignUp,
    });
    window.location = `/users/auth/linkedin?${query}`;
  }

  render() {
    return (
      <div className='linkedin-connect'>
        <span onClick={this.handleClick}>
          <div className='linkedin-button center-block'>
            <img className='linkedin-logo' src={LinkedinLogo} />
            <div className='linkedin-button-text'>
              Sign in with LinkedIn
            </div>
          </div>
        </span>
      </div>
    );
  }
}

LinkedInConnect.propTypes = {
  isSignUp: PropTypes.bool,
  password: PropTypes.string,
  linkedin_url: PropTypes.string,
  referralMedium: PropTypes.string,
  onboardingStatus: PropTypes.string,
};

export default LinkedInConnect;
