import React from 'react';
import PropTypes from 'prop-types';

import LinkedInConnect from 'components/LinkedInConnect';

class SignUpModalContents extends React.Component {
  render() {
    return (
      <div className='signup auth-modal'>
        <h3 className='welcome text-center'>
          Welcome!
        </h3>
        <p className="help-text text-center">
          To make things easy, we&apos;ll grab your basic information from LinkedIn.
        </p>
        <LinkedInConnect
          isSignUp={true}
          linkedin_url={this.props.linkedin_url}
          password={this.props.password}
          referralMedium={this.props.referralMedium}
          onboardingStatus="incomplete"
        />
      </div>
    );
  }
}

SignUpModalContents.propTypes = {
  referralMedium: PropTypes.string.isRequired,
  password: PropTypes.string,
  linkedin_url: PropTypes.string,
};

export default SignUpModalContents;
