import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { some, includes, toLower } from 'lodash';

import { Field, reduxForm } from 'redux-form';
import { Checkbox } from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';

import {
  gitHubUrlValidator,
  googleUrlValidator,
  limitMultiselectOptionsValidator,
  limitSpecialCharactersValidator,
  linkedInFormValidator,
  requiredFormValidator,
  websiteValidator,
} from 'helpers/application';
import { regionsForCandidates } from '../selectors/selectors';

import StyledTextField from 'components/StyledTextField';
import StyledMultiSelectReduxField from 'components/StyledMultiSelectReduxField';
import FileUploadField from 'components/FileUploadField';
import Button from 'components/Button';
import LinkedGlyphiconWithTooltip from 'components/LinkedGlyphiconWithTooltip';

import 'stylesheets/application/user-info-form.less';

const styles = {
  label: {
    'font-size': 18,
  },
  listItemText: {
    'font-size': 18,
  },
  helpText: {
    'font-size': 12,
  },
  shareText: {
    'font-size': 16,
    'line-height': 1.2,
  },
  textField: {
    'margin-bottom': 15,
  },
  form: {
    'margin-bottom': 100,
  },
};

class UserInfoForm extends React.Component {
  renderStyledMenuItem(value, label, classes) {
    return (
      <MenuItem
        key={value}
        value={value}
        className={classes.label}
      >
        {label}
      </MenuItem>
    );
  }
  render() {
    const { classes } = this.props;
    const { resumeUrl } = this.props.initialValues;
    const engineeringTitles = ["engineer", "developer"];
    const isEngineer = some(engineeringTitles, (el) => includes(toLower(this.props.title), el));
    return (
      <div className='user-info-form'>
        <form onSubmit={this.props.handleSubmit} className={classes.form}>
          <h3>About You</h3>
          <hr/>
          <StyledTextField
            name='firstName'
            label='First name'
            classes={classes}
            validate={[requiredFormValidator, limitSpecialCharactersValidator]}
          />
          <StyledTextField
            name='lastName'
            label='Last name'
            classes={classes}
            validate={[requiredFormValidator, limitSpecialCharactersValidator]}
          />
          <StyledTextField
            name='email'
            label='Email'
            classes={classes}
            validate={[requiredFormValidator]}
          />
          <StyledTextField
            name='title'
            label='Title'
            classes={classes}
            validate={[requiredFormValidator, limitSpecialCharactersValidator]}
          />
          <StyledTextField
            name='companyName'
            label='Company'
            classes={classes}
            validate={[requiredFormValidator, limitSpecialCharactersValidator]}
          />
          <StyledTextField
            name='linkedinUrl'
            label='LinkedIn URL'
            classes={classes}
            validate={[requiredFormValidator, linkedInFormValidator]}
          />
          <h4>Resume</h4>
          <div className='resume-label-container'>
            <FormLabel
              className={classes.label}
            >
              {resumeUrl ? "" : "Upload Resume/CV"}
            </FormLabel>
            {resumeUrl && (
              <LinkedGlyphiconWithTooltip
                url={resumeUrl}
                glyphiconKey={'download-alt'}
                tooltipText={'Download your resume'}
                isDownloadEnabled
              />
            )}
          </div>
          <div className='resume-upload-container'>
            <Field name='resume' component={FileUploadField} />
          </div>
          <FormLabel>OR</FormLabel>
          <StyledTextField
            name='secondaryResumeUrl'
            label='Resume Link'
            helperText="Share your Google Docs/Drive resume link"
            classes={classes}
            validate={[googleUrlValidator]}
          />
          <h4>Websites</h4>
          <StyledTextField
            name='websiteUrl'
            label='Website URL'
            classes={classes}
            validate={[websiteValidator]}
          />
          <StyledTextField
            name='portfolioUrl'
            label='Portfolio URL'
            classes={classes}
            validate={[websiteValidator]}
          />
          <StyledTextField
            name='githubUrl'
            label='GitHub URL'
            classes={classes}
            validate={[gitHubUrlValidator]}
          />
          <StyledTextField
            name="bio"
            label="Bio"
            helperText='Sharing more details about yourself will help you stand out to companies.'
            placeholder='Example: I am a results-driven marketer at Uber with experience across brand, product, and consumer marketing. I have experience creating brand identity, affinity, and awareness to lead go-to-market strategy, to build strategic partnerships, and to launch new markets and channels. I’m passionate about opportunities to build and grow disruptive brands, and to drive meaningful impact through thoughtful, creative marketing strategy.'
            classes={classes}
            multiline={true}
            inputProps={{
              style: {
                height: "300px",
              },
            }}
          />
          <h4>Skills</h4>
          <StyledMultiSelectReduxField
            name='skillIds'
            label='Your Skills'
            helperText='Select all that apply'
            optionObjects={this.props.skills}
            validate={isEngineer ? [requiredFormValidator] : []}
          />
          <h3>About Your Job Search</h3>
          <hr/>
          <StyledMultiSelectReduxField
            name='regionIds'
            label='Location'
            helperText='Where would you like to work? (Select up to 10)'
            optionObjects={this.props.regions}
            validate={[requiredFormValidator, limitMultiselectOptionsValidator]}
          />
          <StyledTextField
           name='experienceLevelId'
           label='Experience Level'
           helperText='How long have you been working professionally?'
           select={true}
           classes={classes}
           validate={[requiredFormValidator]}
          >
           {this.props.experienceLevels.map((experienceLevel) => {
             return this.renderStyledMenuItem(experienceLevel.id, experienceLevel.name, classes);
           })}
          </StyledTextField>
          <StyledTextField
            name='primaryFunctionalAreaId'
            label='Primary Role'
            helperText='What role are you primarily seeking?'
            select={true}
            classes={classes}
            validate={[requiredFormValidator]}
          >
            {this.props.functionalAreas.map((functionalArea) => {
              return this.renderStyledMenuItem(functionalArea.id, functionalArea.name, classes);
            })}
          </StyledTextField>
          <StyledMultiSelectReduxField
            name='functionalAreaIds'
            label='Other Interests'
            helperText={`Are there any other roles that you'd like to be considered for?`}
            optionObjects={this.props.functionalAreas}
          />
          <StyledMultiSelectReduxField
            name='roleTypeIds'
            label='Type of Role'
            helperText='What types of roles are you interested in?'
            optionObjects={this.props.roleTypes}
            validate={[requiredFormValidator]}
          />
          <StyledMultiSelectReduxField
            name='companySizeBucketIds'
            label='Company Size'
            helperText='What size company do you prefer?'
            optionObjects={this.props.companySizeBuckets}
            validate={[requiredFormValidator]}
          />
          <StyledMultiSelectReduxField
            name='industryIds'
            label='Desired Industries'
            helperText='Select all that apply'
            optionObjects={this.props.industries}
          />
          <StyledTextField
            name='whenLooking'
            label='Timing'
            helperText='When are you looking to change jobs?'
            select={true}
            classes={classes}
            validate={[requiredFormValidator]}
          >
            {this.props.whenLooking.map((timeframe) => {
              return this.renderStyledMenuItem(timeframe, timeframe, classes);
            })}
          </StyledTextField>
          <StyledTextField
            name="referralSource"
            label="How did you hear about us?"
            helperText='Please be specific'
            classes={classes}
            validate={[requiredFormValidator]}
            multiline={true}
            inputProps={{
              maxLength: 255,
            }}
          />
          <StyledTextField
            name="desiredSalary"
            label="What is your desired compensation?"
            helperText="(in $)"
            classes={classes}
            inputProps={{
              maxLength: 8,
              type: "number"
            }}
          />
          <StyledTextField
            name='requiresSponsorship'
            label='Require visa sponsorship to work in the US?'
            helperText='Will you now or in the future require sponsorship for employment visa status?'
            select={true}
            classes={classes}
            validate={[requiredFormValidator]}
          >
            {this.renderStyledMenuItem(true, `Yes`, classes)}
            {this.renderStyledMenuItem(false, `No`, classes)}
          </StyledTextField>
          <FormControl className={classes.formControl}>
            <FormLabel
              className={classes.label}
            >
              Permission to Share
            </FormLabel>
            <FormHelperText
              className={classes.shareText}
            >
              Do we have your permission to confidentially share your information with First Round companies? <b>Your information will never, ever, be shared publicly.</b>
            </FormHelperText>
            <FormControlLabel
              label="Yes"
              classes={{label: classes.label}}
              control={
                <Field
                  name="sharingAllowed"
                  component={Checkbox}
                />
              }
            />
          </FormControl>
          <StyledTextField
            name='permissionForTalentNewsletter'
            label='Talent Newsletter Opt-In'
            helperText='Our Talent Team sends a Talent Newsletter to First Round founders and recruiters where we feature candidates open to new roles. Are you interested in being featured in the next newsletter?'
            select={true}
            classes={classes}
            validate={[requiredFormValidator]}
          >
            {this.renderStyledMenuItem(true, `Yes, please!`, classes)}
            {this.renderStyledMenuItem(false, `Not at this time`, classes)}
          </StyledTextField>
          <div className='footer'>
            <Button
              displayType={this.props.submitting || this.props.invalid ? `default`: `primary`}
              type='submit'
              disabled={this.props.submitting || this.props.invalid}
            >
              Save
            </Button>
            {this.props.invalid &&
              <FormHelperText
                className={classes.helpText}
              >
                Please fill out required fields
              </FormHelperText>
            }
          </div>
        </form>
      </div>
    );
  }
}

UserInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired, // redux-form passes this in
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  regions: PropTypes.array.isRequired,
  functionalAreas: PropTypes.array.isRequired,
  companySizeBuckets: PropTypes.array.isRequired,
  whenLooking: PropTypes.array.isRequired,
  roleTypes: PropTypes.array.isRequired,
  skills: PropTypes.array.isRequired,
  industries: PropTypes.array.isRequired,
  title: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    initialValues: {...state.user, onboarding_status: `complete`},
    regions: regionsForCandidates(state),
    functionalAreas: state.meta.functionalAreas,
    companySizeBuckets: state.meta.companySizeBuckets,
    whenLooking: state.meta.whenLooking,
    roleTypes: state.meta.roleTypes,
    skills: state.meta.skills,
    industries: state.meta.industries,
    title: state.user.title,
    experienceLevels: state.meta.experienceLevels,
  };
}

const UserInfoReduxForm = reduxForm({
  form: `userInfo`, // a unique identifier for this form
})(UserInfoForm);

const UserInfoFormWithState = connect(
  mapStateToProps
)(UserInfoReduxForm);

export default withStyles(styles)(UserInfoFormWithState);
