import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { camelCase, transform } from 'lodash';
import { sanitizeNameField } from '../helpers/application';

import { submitUserInfo } from 'actions/actions';

import { Col } from 'react-bootstrap';

import LoggedInPage from 'components/LoggedInPage';
import UserInfoForm from 'components/UserInfoForm';
import OpportunityReferralModal from './OpportunityReferralModal';
import { getInfoUpdatedRedirectLinkForUser } from '../helpers/application';

import 'stylesheets/application/user-info-page.less';

class UserInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referralModalOpen: false,
      values: {},
    },
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }
  handleSubmit(values) {
    this.setState({referralModalOpen: true});
    this.setState({values: values});
  }
  closeModal() {
    this.handleSave();
    this.setState({referralModalOpen: false});
  }
  handleSave() {
    const submittedValues = { ...this.state.values };
    // The server expects a boolean value so we we convert null to false
    submittedValues.sharingAllowed = !!submittedValues.sharingAllowed;
    submittedValues.firstName = sanitizeNameField(submittedValues.firstName);
    submittedValues.lastName = sanitizeNameField(submittedValues.lastName);
    this.setState({values: submittedValues})
    this.props.submitUserInfo({user: submittedValues}).catch(error => {
      const errorObject = transform(error.body.error, (result, value, key) => {
        result[camelCase(key)] = value.join(`, `);
      }, {});
      throw new SubmissionError(errorObject);
    }).then(() => {
      // Hard redirect yields a GET to the server for the page, as opposed to an SPA router-redirect.
      window.location = getInfoUpdatedRedirectLinkForUser(this.props.user);
    });
  }

  render() {
    return (
      <Col className='user-info-page' md={8} mdOffset={2}>
        <div className='user-form-container'>
          <UserInfoForm onSubmit={this.handleSubmit} />
          <OpportunityReferralModal open={this.state.referralModalOpen} closeModal={this.closeModal} />
        </div>
      </Col>
    );
  }
}

UserInfoPage.propTypes = {
  submitUserInfo: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};


function mapDispatchToProps(dispatch) {
  return {
    submitUserInfo: (request) => dispatch(submitUserInfo(request)),
  };
}

const UserInfoPageWithState = connect(
  null,
  mapDispatchToProps
)(UserInfoPage);

export default LoggedInPage(UserInfoPageWithState);
